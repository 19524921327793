import * as Enums from "./../../../framework/enum.shared";
import * as InterfaceProduct from "./../../../framework/interface.product";

import { ApprovalHistory } from "./approval-history.model";
import { Echo } from "./echo.model";
import { EndorsementClause } from "./endorsement-cluase.model";
import { EndorsementHistory } from "./endorsement-history.model";
import { ExceptionManager } from "./../../../framework/utils/exception-manager";
import { GUID } from "./../../../framework/domain-entity/guid";
import { PremiumSummary } from "./premium-summary.model";
import { ProposalHeader } from "./proposal-header";
import { ProposalPendingTransaction } from "./proposal-pending-transaction.model";
import { QuestionEntity } from "./question-entity.model";
import { common } from "./../../../framework/utils/common";
import { environment } from "./../../../../environments/environment";

export class ProposalEntity {
  constructor(proposalId?: GUID, productId?: GUID) {
    if (common.isDefined(productId)) {
      this.productId = productId;
    } else {
      this.productId = new GUID();
    }

    if (common.isDefined(proposalId)) {
      this.proposalId = proposalId;
    } else {
      this.proposalId = new GUID();
    }
  }

  /** Entity return from back-end */
  serverEntity: any;
  /** Unique numeric ID used by front-end */
  get proposalKey(): number {
    return this.proposalId!.toId();
  }

  /** pdid. Proposal Product GUID */
  productId?: GUID;
  /** pid Proposal GUID */
  proposalId?: GUID;
  /** Quote Number */
  quoteNumber?: string;
  /** Policy Number */
  policyNumber?: string;
  /** Policy Certificate Number */
  policyCertificateNumber?: string;
  /** section. Page/Panel section */
  questionSection?: number;
  /** e. Proposal effective date */
  effectiveDate?: Date;
  /** qas. Proposal Question Answer list */
  questions?: QuestionEntity[];
  /** sm. System message to the user */
  systemMessages?: Echo[];
  /** um. Underwriter message to the user. Usually contains proposal referred message */
  underwriterMessages?: Echo[];
  /** vm. Proposal validation message to the user. Usually contains mandatory question not answered message */
  validationsMessages?: Array<[string]>;

  /** custid. dbo.CustomerMapping.CustomerMapping */
  customerMappingID?: number;
  /** custAddid. dbo.Address.AddressId. AddressId of mapped customer */
  customerMappingAddressID?: number;
  /** custt. Mapped customer type */
  customerType?: Enums.CommonForeignType;
  /** custcountryISO2. Mapped customer country ISO2 */
  customerCountryISO2?: string;

  /** Proposal Header */
  header?: ProposalHeader[];
  /** pre. Proposal premium */
  proposalPremiumModel?: PremiumSummary;
  /** ro. Flag to determine if whole proposal is read only */
  proposalReadOnly?: boolean;
  /** tt. Proposal transaction type */
  transType?: Enums.TransType;
  /** ddt. List of available document for download */
  downloadDocument?: Enums.DocumentType[];
  /** im. Flag to determine if this migrated proposal */
  isMigrated?: boolean;
  /** child proposal */
  children?: ProposalEntity[];
  /** Child question schema */
  childQuestionSchema?: QuestionEntity[];
  /** Proposal Title */
  title?: string;

  /** BrokerID */
  producerOrganisationId!: number;
  /** BrokerUserID */
  producerUserId!: number;
  /*Broker Commission*/
  brokerCommission?: number;
  /** Approval History */
  approvalHistory?: ApprovalHistory[];
  /** Approval History Dictionary */
  approvalHistoryDictionary?: any;
  /** Endorsement clause table */
  endorsementClauseTable?: EndorsementClause[];
  /** Auxiliary data - generic to be use by a lot of things */
  auxiliary?: any;
  /** Downloadable Documents */
  downloadableDocuments?: Enums.DocumentType[];
  /** Flag to determine if this proposal/child is newly added by current transaction */
  isNewlyAddedByCurrentTransaction?: boolean;

  /** Proposal Question Dictionary Group by QuestionName */
  questionDictionary!: { [key: string]: QuestionEntity };
  /** Proposal Question Dictionary Group by QuestionPanelId */
  questionPanelDictionary!: { [key: string]: QuestionEntity[] };
  /** Previous Proposal Question Dictionary Group by QuestionName */
  previousPolicyQuestions!: { [key: string]: any };

  /** Proposal Status. dbo.Proposal.ProposalAdditionalStatusId */
  proposalStatus!: Enums.ProposalStatus;
  /** Proposal Type */
  proposalType!: Enums.ProposalType;

  /** DBS bank reference number */
  bankReferenceNo?: string;
  /** Master policy number */
  masterPolicyNumber?: string;
  /** Associated Client Code */
  clientCode?: string;
  /** Last submitted Maker UserId */
  makerUserId?: number;
  /** Last Approved/Return for revise Checker UserId */
  checkerUserId?: number;

  /** Current Policy Pending Transaction ProposalId */
  pendingTransactionProposalId?: GUID;
  /** Current Policy Pending Transaction TransType */
  pendingTransactionType?: Enums.TransType;

  /** Flag to indicate endorsement type while performing endorsement action */
  endorsementType?: number = 1; // default to 1 General Endorsement

  /** Flag to indicate if loan fully redeemed */
  loanRedeemed?: boolean;

  /** Pending transaction of existing policy */
  pendingTransaction?: ProposalPendingTransaction;
  /** endorsement history of existing policy */
  endorsementHistory?: EndorsementHistory[];
  /** Original Inception Date */
  originalInceptionDate?: any = new Date(); // default to current Date
  /** Minimum Policy Effective Date */
  minimumEffectiveDate?: any;
  makerUserName?: any;
  checkerUserName?: any;
  openItems?: any;
  isPremiumManualOverridden?: boolean;
  /** Quote submission */
  quotes?: InterfaceProduct.IQuote[];
  //DBSEP-8115
  isPolicyMasked?: any;
  MaskedDate?: any;
  bindQuestionDictionary(): void {
    this.questionDictionary = {};
    if (common.isDefined(this.questions)) {
      this.questions?.forEach((question) => {
        this.questionDictionary[question.key.trim().toLowerCase()] = question;
      });
    }
  }

  bindQuestionPanelDictionary(): void {
    this.questionPanelDictionary = {};
    if (common.isDefined(this.questions)) {
      this.questions?.forEach((question) => {
        if (common.isDefined(question.questionPanelId)) {
          const guid = question.questionPanelId?.toString();
          if (guid) {
            if (common.isUndefinedOrNull(this.questionPanelDictionary[guid])) {
              this.questionPanelDictionary[guid] = [];
            }
            this.questionPanelDictionary[guid].push(question);
          }
        }
      });
    }
  }

  bindPreviousPolicyQuestionDictionary(
    controls: InterfaceProduct.IUiControl[]
  ): void {
    this.previousPolicyQuestions = {};
    controls.forEach((ui) => {
      if (ui && ui.k) {
        this.previousPolicyQuestions[ui.k] = ui.v;
      }
    });
  }

  getPreviousPolicyQuestionAnswer(questionName: string) {
    if (common.isUndefinedOrNull(this.previousPolicyQuestions[questionName])) {
      return undefined;
    }

    return this.previousPolicyQuestions[questionName];
  }

  refreshQuestionDictionary(): void {
    this.questionDictionary = {};
    this.questions?.forEach((question) => {
      this.questionDictionary[question.key.trim().toLowerCase()] = question;
    });
  }

  /** Retrieved particular question by question name  */
  getQuestion(key: string): QuestionEntity | undefined {
    if (common.isUndefinedOrNull(key)) {
      ExceptionManager.error("Question [Key] not provided");
    }

    if (common.isUndefinedOrNull(this.questions)) {
      ExceptionManager.error("Questions is undefined");
    }

    key = key.trim().toLowerCase();

    if (common.isUndefinedOrNull(this.questionDictionary)) {
      this.refreshQuestionDictionary();
    }

    const question = this.questionDictionary[key];

    if (common.isUndefinedOrNull(question)) {
      if (!environment.production) {
        // console.log(`[${key}] not existed in the question list`);
      }
      return undefined;
    }

    return question;
  }

  /** To check if question existed */
  isQuestionExists(key: string): boolean {
    if (common.isUndefinedOrNull(key)) {
      ExceptionManager.error("Question [Key] not provided");
    }

    if (common.isUndefinedOrNull(this.questions)) {
      ExceptionManager.error("Questions is undefined");
    }

    key = key.trim().toLowerCase();

    return !common.isUndefinedOrNull(this.questionDictionary[key]);
  }
}
