import { Component, Inject, OnInit } from "@angular/core";
import { DialogRef } from "src/app/components/shared/partials/dialog/dialog-ref";
import { DIALOG_DATA } from "src/app/components/shared/partials/dialog/dialog-tokens";
import { dlgModelArgs } from "src/app/components/shared/partials/md-confirmation-message-v1/md-confirmation-message-v1.component";
import { clientViewDlgModelArgs } from "src/app/components/shared/partials/mdClient/client-view/client-view.component";
import { KeyValue } from "src/app/framework/domain-entity/key-value";
import { common } from "src/app/framework/utils/common";
import { CdkDialogService } from "src/app/services/dialog/cdk-dialog.service";
import { IdentityRolesService } from "src/app/services/identity-roles.service";
import { MessageBoardService } from "src/app/services/message-board.service";
import { MdValidationMessageComponent } from "../../sme/masterPolicy/md-validation-message/md-validation-message.component";
import { ClientTimelineComponent } from "../client-timeline/client-timeline.component";
import { MessageBoardComponent } from "../message-board/message-board.component";
import { UnderwriterInternalCommentComponent } from "../underwriter-internal-comment/underwriter-internal-comment.component";
import { MessageBoardModel } from "./MessageBoardModel";
import {SharedService} from "src/app/shared/shared.service"
import { v2DlgInputArgs } from "src/app/components/shared/partials/md-product-selection-dialog/md-product-selection-dialog.component";
import { MdAlertMessageV1Component } from "src/app/components/shared/partials/md-alert-message-v1/md-alert-message-v1.component";

@Component({
  selector: "app-message-forum",
  templateUrl: "./message-forum.component.html",
  styleUrls: ["./message-forum.component.scss"],
})
export class MessageForumComponent implements OnInit {
  model: MessageBoardModel = new MessageBoardModel();
  data!: any;
  showSummaryDocumentButton = false;
  showReprintFunction = false;
  showCustomerTimeline = false;
  count = 0;
  response = "";
  isProcessing = false;
  hasFormError = false;
  formErrors = "";
  confirmationMsg!: string;

  options: KeyValue<string>[] = [
    {
      key: "Email",
      value: "2",
    },
    {
      key: "SMS",
      value: "5",
    },
    {
      key: "Mail",
      value: "1",
    },
  ];
  selectedPrintType: any;
  isInvalidEmailAddress = false;

  //dbsep-8115
  isPolicyMasked!: string;
  maskedDate!: Date;
  displayPolicyMasked: boolean = false;

  constructor(
    private dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public inputData: any,
    public identityRolesService: IdentityRolesService,
    private messageBoardService: MessageBoardService,
    private cdkDialogService: CdkDialogService,
    private shared:SharedService
  ) {
    if (this.inputData) {
      this.data = {
        proposalId: this.inputData.proposalId,
        isUnderwriterInternalComment:
          this.inputData.isUnderwriterInternalComment,
        productId: this.inputData.productId,
        printCorrespondence: this.inputData.printCorrespondence,
        policyNumber: this.inputData.policyNumber,
        transType: this.inputData.transType,
        proposalStatus: this.inputData.proposalStatus,
        loanRedeemed: this.inputData.loanRedeemed,
        preferredDeliveryMethod: this.inputData.preferredDeliveryMethod,
        emailId: this.inputData.emailId,
        mobileNo: this.inputData.mobileNo,
        contactInfo: this.inputData.contactInfo,
        policyExpiryDate: this.inputData.policyExpiryDate,
        proposalType: this.inputData.proposalType,
        pendingTransaction: this.inputData.pendingTransaction,
        isPreviousYearTerminated: this.inputData.isPreviousYearTerminated,
        allowRenewal: this.inputData.allowRenewal,
        confimationMsg: this.inputData.confimationMsg,
      };

      // this.model = MessageBoardModelFactory.buildMessageBoard();
      this.model.proposalId = this.data.proposalId;
      this.model.isUnderwriterInternalComment =
        this.data.isUnderwriterInternalComment;
      this.model.productId = this.data.productId;
      this.model.printCorrespondence = this.data.printCorrespondence;
      this.model.createdByUserId = this.identityRolesService
        .getIdentity()
        .currentUser().id; //$parent.currentUser.id;
    }

    
  }
  
  hasMbRecords() {
    if (common.isDefined(this.model.messageBoard)) {
      return this.model.messageBoard.length > 0;
    } else {
      return false;
    }
  }

  ngOnInit(): void {
    if (this.data.isUnderwriterInternalComment) {
      this.getInternalUnderwriterComment();
    } else {
      this.getMessageHistory();
    }
    this.isProcessing = false;

    this.model.selectedPrintType = this.options.filter((item) => {
      return item.value == this.data.preferredDeliveryMethod;
    })[0];

    if (this.model.selectedPrintType == null) {
      this.model.selectedPrintType = this.options[1];
    }

    //https://github.com/udos86/ng-dynamic-forms/issues/362
    //overhead for non-primitive
    this.selectedPrintType = this.model.selectedPrintType.value;

    this.model.isPasswordProtected = true;
    this.showSummaryDocumentButton = false;
    this.showReprintFunction = false;
    this.showCustomerTimeline = false;
    if (
      (this.data.transType == 1 || this.data.transType == 4) &&
      this.data.proposalStatus == 6
    ) {
      this.showSummaryDocumentButton = false;
      this.showReprintFunction = false;
    } else if (this.data.policyNumber) {
      this.showSummaryDocumentButton = true;
      this.showReprintFunction = true;
    }
    if (
      (this.model.productId == "06CB09D3-6FE0-4695-92A8-A61DFB627C61" ||
        this.model.productId == "8533FA05-F190-4A29-9613-3DB7F693DD32") &&
      this.data.loanRedeemed
    ) {
      this.showSummaryDocumentButton = false;
    }
    if (this.data.transType == 3 && this.data.proposalStatus != 6) {
      this.showSummaryDocumentButton = false;
    }
    if (
      this.identityRolesService.isChecker ||
      this.identityRolesService.isMaker
    ) {
      this.showReprintFunction = false;
    }
    var currentDateTime = new Date();
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    var updateMultiyearChoiceFlag = 0;

    var mthDiff = this.monthDiff(
        new Date(currentDateTime.getFullYear(), currentDateTime.getMonth()),
        new Date(
            this.data.policyExpiryDate.getFullYear(),
            this.data.policyExpiryDate.getMonth()
        )
    );

    let visibilityMonth = new Date(this.data.policyExpiryDate).getMonth();
    let visibilityYear = new Date(this.data.policyExpiryDate).getFullYear();
    if (visibilityMonth == 0) {
        // for jan, month = 0, so taking Dec month of previous year
        visibilityMonth = 12;
        visibilityYear = visibilityYear - 1;
    }
    let visibilityDate = new Date(visibilityYear + "-" + visibilityMonth);
    //Take one bay before from auto renewal job date
    visibilityDate.setDate(14);

    let currentDate = new Date(
        currentDateTime.getFullYear() +
        "-" +
        //Add one month due to month number starts from 0(eg., jan = 0, Feb = 1) in angular
        (currentDateTime.getMonth() + 1) +
        "-" +
        currentDateTime.getDate()
    );
    if (new Date(currentDate) <= new Date(visibilityDate) && mthDiff <= 2) {
        updateMultiyearChoiceFlag = 1;
    }

    if (
        this.model.productId == "2396885E-49F6-43F6-A314-47D727AB888F" ||
        this.model.productId == "6FAF6BEE-F5FC-4DC2-9F75-37FE2D61611D" ||
        this.model.productId == "565E9075-9F73-45C0-BA5C-A78AC05BA837"
    ) {
        let renewalDraftVisibilityYear = new Date(visibilityDate).getFullYear();
        let renewalDraftCurrentYear = new Date(currentDateTime).getFullYear();
        let yearDiff = renewalDraftVisibilityYear - renewalDraftCurrentYear;
        if (yearDiff == 3)
            renewalDraftVisibilityYear = renewalDraftVisibilityYear - 3;
        else if (yearDiff == 1)
            renewalDraftVisibilityYear = renewalDraftVisibilityYear - 1;
        else renewalDraftVisibilityYear = renewalDraftVisibilityYear;

        let renewalDraftvisibilityDate = new Date(
            renewalDraftVisibilityYear + "-" + visibilityMonth
        );
        renewalDraftvisibilityDate.setDate(14);

        var renewalDraftMthDiff;
        if (yearDiff == 3)
            renewalDraftMthDiff = this.monthDiff(
                new Date(currentDateTime.getFullYear(), currentDateTime.getMonth()),
                new Date(
                    this.data.policyExpiryDate.getFullYear() - 3,
                    this.data.policyExpiryDate.getMonth()
                )
            );
        else if (yearDiff == 1)
            renewalDraftMthDiff = this.monthDiff(
                new Date(currentDateTime.getFullYear(), currentDateTime.getMonth()),
                new Date(
                    this.data.policyExpiryDate.getFullYear() - 1,
                    this.data.policyExpiryDate.getMonth()
                )
            );
        else
            renewalDraftMthDiff = this.monthDiff(
                new Date(currentDateTime.getFullYear(), currentDateTime.getMonth()),
                new Date(
                    this.data.policyExpiryDate.getFullYear(),
                    this.data.policyExpiryDate.getMonth()
                )
            );

        if (
            this.data.transType === 4 &&
            this.data.proposalStatus === 6 &&
            new Date(currentDate) <= new Date(renewalDraftvisibilityDate) &&
            renewalDraftMthDiff <= 2
        )
            this.showCustomerTimeline = true;
        else if (
            (this.data.transType === 1 ||
                this.data.transType === 2 ||
                this.data.transType === 4) &&
            this.data.proposalStatus === 2 &&
            this.data.pendingTransaction == null &&
            this.data.isPreviousYearTerminated != true &&
            updateMultiyearChoiceFlag === 1 &&
            this.data.transType != 3 &&
            !this.data.loanRedeemed
        )
            this.showCustomerTimeline = true;
    }
    //angular.element('#selectReprintType').kendoDropDownList();//todo
    //dbsep-8115
    this.isPolicyMasked = this.shared.getPolicyMasked();
    this.maskedDate = this.shared.getMaskedDate();

    this.displayPolicyMasked = this.isPolicyMasked == "1"?true:false;
  }

  //do not take days into comparison
  //console.log(monthDiff(new Date(2023, 01, 31), new Date(2023, 02, 01)))
  //console.log(monthDiff(new Date(2023, 01), new Date(2023, 02)))
  monthDiff = function (dateFrom: Date, dateTo: Date) {
    return (
      dateTo.getMonth() -
      dateFrom.getMonth() +
      12 * (dateTo.getFullYear() - dateFrom.getFullYear())
    );
  };

  getMessageHistory() {
    this.messageBoardService
      .getMessage(this.data.proposalId)
      .subscribe((responseModel: any) => {
        setTimeout(() => {
          if (responseModel.data != null && responseModel.data.length > 0) {
            this.count = responseModel.data.length;
            var index = responseModel.data.length;
            responseModel.data.forEach(function (message: {
              selected: boolean;
              rowIndex: number;
              files: any[];
              PolicySummaryVersion: string;
            }) {
              message.selected = false;
              message.rowIndex = index--;
              let policyver = false;

              //message.files.forEach(
              //(data: { fileName: string; description: string }) => {
              //     if (
              //       data.fileName === "Renewal.pdf" ||
              //       data.fileName === "PolicySchedule.pdf" ||
              //       data.fileName === "PolicySchedule_R.pdf" ||
              //       data.fileName === "Renewal_R.pdf" ||
              //       data.description === "Policy summary" ||
              //       data.fileName === "RenewalMultiYear.pdf"
              //     ) {
              //       policyver = true;
              //     }
              // }
              //);

              for (let i = 0; i < message.files.length; i++) {
                if (
                  message.files[i].fileName === "Renewal.pdf" ||
                  message.files[i].fileName === "PolicySchedule.pdf" ||
                  message.files[i].fileName === "PolicySchedule_R.pdf" ||
                  message.files[i].fileName === "Renewal_R.pdf" ||
                  message.files[i].description === "Policy summary" ||
                  message.files[i].fileName === "RenewalMultiYear.pdf"
                ) {
                  policyver = true;
                }
              }

              if (policyver === true) {
                message.PolicySummaryVersion =
                  message.PolicySummaryVersion != ""
                    ? "Policy Summary Version: " +
                      message.PolicySummaryVersion.replace("/#/", "/")
                    : "";
              } else {
                message.PolicySummaryVersion = "";
              }
            });
            this.model.messageBoard = responseModel.data;
          }
        }, 100);
      });
  }

  getInternalUnderwriterComment() {
    this.messageBoardService
      .getInternalUnderwriterComment(this.data.proposalId)
      .subscribe((responseModel: any) => {
        setTimeout(() => {
          if (responseModel.data != null && responseModel.data.length > 0) {
            this.count = responseModel.data.length;
            this.model.messageBoard = responseModel.data;
          }
        }, 100);
      });
  }

  getFile(fileId: any) {
    /**kisiva - Check for browser compatiablity is done here
     * because product id is available here. This change is required
     * to allow bizpack product to ignore browser compatiablity check.
     * Especially for WINBEAT, where lower version browser is able to
     * honor BLOB.
     */
    if(this.isPolicyMasked == "1")
    {
      this.policyMaskedAlert();
    }
    else
    {
      var userAgent = window.navigator.userAgent;
      var msie = userAgent.indexOf("MSIE ");

      var productId = this.model.productId;
      if (
        productId !== undefined &&
        productId.toLocaleUpperCase() === "F6C1BCB1-D045-4DB3-B947-0E50FE0D49EB"
      ) {
        this.messageBoardService.getFile({
          documentId: fileId,
          proposalId: this.data.proposalId,
        });
      } else {
        if (msie > 0) {
          alert("Current browser does not support file download.");
        } else {
          this.messageBoardService.getFile({
            documentId: fileId,
            proposalId: this.data.proposalId,
          });
        }
      }
    }
    
  }

  policyMaskedAlert(){
    const msg =
      "Unable to perform action. This policy customer data has been masked due to compliance guideline.";
    this.confirmationMsg = msg;
    let v2InputData: v2DlgInputArgs = {
      opened: true,
      dialogWidth: 600,
      data: "",
      message: this.confirmationMsg,
    };

    const v1DialogRef = this.cdkDialogService.open(
      MdAlertMessageV1Component,
      {
        data: v2InputData,
      }
    );
  }

  openClientTimeline() {
    var data = {
      proposalId: this.model.proposalId,
      isUnderwriterInternalComment: this.model.isUnderwriterInternalComment,
      productId: this.model.productId,
      policyNumber: this.data.policyNumber,
      createdByUserId: this.model.createdByUserId,
      emailId: this.data.emailId,
      contactInfo: this.data.contactInfo,
      mobileNo: this.data.mobileNo,
      preferredDeliveryMethod: this.model.selectedPrintType.value,
    };
    const v1DialogRef = this.cdkDialogService.open(ClientTimelineComponent, {
      data: data,
    });

    v1DialogRef.afterClosed().subscribe((result: any) => {
      // Subscription runs after the dialog closes
      if (result) {
        this.onAfterSavingMessageBoard(result);
      }
    });
  }

  openMessageBoard(event: { preventDefault: () => void }) {
    if(this.isPolicyMasked == "1"){
      this.policyMaskedAlert();
    }
    else{
      event.preventDefault();

      var data = {
        proposalId: this.model.proposalId,
        isUnderwriterInternalComment: this.model.isUnderwriterInternalComment,
        productId: this.model.productId,
        emailId: this.data.emailId,
        contactInfo: this.data.contactInfo,
        mobileNo: this.data.mobileNo,
        preferredDeliveryMethod: this.model.selectedPrintType.value,
      };

      if (data.isUnderwriterInternalComment) {
        const v1DialogRef = this.cdkDialogService.open(
          UnderwriterInternalCommentComponent,
          {
            data: data,
          }
        );

        v1DialogRef.afterClosed().subscribe((result: any) => {
          // Subscription runs after the dialog closes
          if (result) {
            this.onAfterSavingMessageBoard(result);
          }
        });
      } else {
        const v1DialogRef = this.cdkDialogService.open(MessageBoardComponent, {
          data: data,
        });

        v1DialogRef.afterClosed().subscribe((result: any) => {
          // Subscription runs after the dialog closes
          if (result) {
            this.onAfterSavingMessageBoard(result);
          }
        });
      }
    }
   
  }

  onAfterSavingMessageBoard(args: dlgModelArgs) {
    this.model.messageBoard = args.data;
    //todo
    // // Bug fix for displaying modal window
    // $("body").append('<div class="modal-backdrop fade in"></div>');
    // $('html').css('overflow', 'hidden');
  }

  openDocumentsBoard = function (event: { preventDefault: () => void }) {
    event.preventDefault();
    // var documentModel = {
    //     proposalId: data.proposalId,
    //     isUnderwriterInternalComment: data.isUnderwriterInternalComment,
    //     productId: data.productId,
    // };

    // documentModel.messageBoard = [];

    // this.model.messageBoard.forEach(function (message) {
    //     if (message.files[0].fileName.length > 0) {
    //         documentModel.messageBoard.push(message);
    //     }
    // });

    // Dialog.modalLarge(
    //     'products/messageBoard/directives/drReprintBoard/drReprintBoard.html',
    //     'ReprintBoardController',
    //     documentModel,
    //     onAfterSavingMessageBoard, undefined, undefined, undefined, false, 'standardModal');
  };

  reprint() {
    if(this.isPolicyMasked == "1")
    {
      this.policyMaskedAlert();
    }
    else
    {
      if (
        +this.model.selectedPrintType.value == 2 &&
        !this.validateEmail(this.data.emailId)
      ) {
        this.response = "Invalid Email Address!";
        return;
      }
      if (
        +this.model.selectedPrintType.value == 5 &&
        !this.validateMobile(this.data.mobileNo)
      ) {
        this.response = "Invalid Mobile Number!";
        return;
      }
      if (
        +this.model.selectedPrintType.value == 1 &&
        !this.validateAddress(this.data.contactInfo)
      ) {
        this.response = "Invalid Mail Address!";
        return;
      }

      var proposalEntity: ProposalEntity = new ProposalEntity();
      proposalEntity.proposalId = this.model.proposalId;
      proposalEntity.printType = this.model.selectedPrintType.value;
      proposalEntity.printCorrespondence = this.model.printCorrespondence;
      proposalEntity.isReprint = true;
      proposalEntity.isSummary = false;
      proposalEntity.isManual = false;
      proposalEntity.isPasswordProtected = this.model.isPasswordProtected;
      proposalEntity.fileModels = [];
      if (this.model.messageBoard.length != undefined) {
        this.model.messageBoard.forEach((message: any) => {
          if (message.selected) {
            message.files.forEach((file: { fileId: any }) => {
              proposalEntity.fileModels.push({
                fileId: file.fileId,
                description: "Post: " + message.rowIndex,
              });
              if (message.rowIndex == undefined) {
                this.close("no");
                this.showMessage(
                  "Sorry, something went wrong. Please try again."
                );
                return;
              }
            });
          }
        });
      }
      if (proposalEntity.fileModels.length == 0) {
        this.showMessage("Please select Message for Reprint");
        return;
      }
      this.messageBoardService.savePrintOptions(proposalEntity).subscribe(
        (model: any) => {
          if (this.data.isUnderwriterInternalComment) {
            this.getInternalUnderwriterComment();
          } else {
            this.getMessageHistory();
          }
        },
        (result: { statusText: any }) => {
          this.hasFormError = true;
          this.formErrors = result.statusText;
        }
      );
    }
  }

  validateAddress(address: any) {
    var valid = false;
    if (address !== "" && address !== null) {
      this.isInvalidEmailAddress = false;
      this.model.printCorrespondence = address;
      valid = true;
    } else {
      this.isInvalidEmailAddress = true;
      valid = false;
      //todo
      //angular.element('.alertLogin.invalidEmail').fadeIn(500);
      //setTimeout("$('.alertLogin.invalidEmail').fadeOut(1500);", 3000);
    }
    return valid;
  }

  validateEmail(email: any) {
    var reg = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
    var valid = false;
    if (email !== "" && reg.test(email)) {
      this.isInvalidEmailAddress = false;
      this.model.printCorrespondence = email;
      valid = true;
      this.response = "";
    } else {
      this.isInvalidEmailAddress = true;
      valid = false;
      this.response = "Invalid Email Address!";
      //todo
      //angular.element('.alertLogin.invalidEmail').fadeIn(500);
      //setTimeout("$('.alertLogin.invalidEmail').fadeOut(1500);", 3000);
    }
    return valid;
  }

  validateMobile(mobile: any) {
    var reg = /^\+?[0-9]+$/;
    var valid = false;
    if (
      mobile.length == 8 &&
      mobile !== "" &&
      reg.test(mobile) &&
      (mobile.toString()[0] == "9" || mobile.toString()[0] == "8")
    ) {
      this.isInvalidEmailAddress = false;
      valid = true;
      this.response = "";
      this.model.printCorrespondence = mobile;
    } else {
      this.isInvalidEmailAddress = true;
      valid = false;
      this.response = "Invalid Mobile Number!";
      //todo
      //angular.element('.alertLogin.invalidEmail').fadeIn(500);
      //setTimeout("$('.alertLogin.invalidEmail').fadeOut(1500);", 3000);
    }
    return valid;
  }

  optionChange(event: any) {
    //https://github.com/udos86/ng-dynamic-forms/issues/362
    //overhead for non-primitive
    this.model.selectedPrintType = this.options.filter((item) => {
      return item.value == this.selectedPrintType;
    })[0];

    this.response = "";
    if (+this.model.selectedPrintType.value == 2) {
      this.validateEmail(this.data.emailId);
    }
    if (+this.model.selectedPrintType.value == 5) {
      this.validateMobile(this.data.mobileNo);
    }
  }

  showMessage(message: any) {
    var validateDate = { validationMessages: message, title: "Alert" };

    const v1DialogRef = this.cdkDialogService.open(
      MdValidationMessageComponent,
      {
        data: validateDate,
      }
    );

    v1DialogRef.afterClosed().subscribe((result: any) => {
      // Subscription runs after the dialog closes
      if (result) {
      }
    });
  }

  printSummary() {
    if(this.isPolicyMasked == "1")
    {
      this.policyMaskedAlert();
    }
    else
    {
      if (
        +this.model.selectedPrintType.value == 2 &&
        !this.validateEmail(this.data.emailId)
      ) {
        return;
      }
      if (
        +this.model.selectedPrintType.value == 5 &&
        !this.validateMobile(this.data.mobileNo)
      ) {
        return;
      }
      if (
        +this.model.selectedPrintType.value == 1 &&
        !this.validateAddress(this.data.contactInfo)
      ) {
        this.response = "Invalid Mail Address!";
        return;
      }
      var proposalEntity: ProposalEntity = new ProposalEntity();
      proposalEntity.proposalId = this.model.proposalId;
      proposalEntity.printType = this.model.selectedPrintType.value;
      proposalEntity.printCorrespondence = this.model.printCorrespondence;
      proposalEntity.isReprint = false;
      proposalEntity.isSummary = true;
      proposalEntity.isManual = false;
      proposalEntity.isPasswordProtected = this.model.isPasswordProtected;
  
      this.messageBoardService.savePrintOptions(proposalEntity).subscribe(
        (model: any) => {
          if (this.data.isUnderwriterInternalComment) {
            this.getInternalUnderwriterComment();
          } else {
            this.getMessageHistory();
          }
        },
        (result: { statusText: any }) => {
          this.hasFormError = true;
          this.formErrors = result.statusText;
        }
      );
    }
    
  }

  getMessage = function (proposalId: any) {
    // MessageBoard.getMessage(proposalId)
    //     .then(function (model) {
    //         $timeout(function () {
    //             if (model.data != null && model.data.length > 0) {
    //                 model.data.forEach(function (element) {
    //                     element.PolicySummaryVersion=element.PolicySummaryVersion!=""?"Policy Summary Version: "+element.PolicySummaryVersion:"";
    //                 });
    //                 this.model.messageBoard = model.data;
    //                 $modalInstance.close(this.model.messageBoard);
    //             } else {
    //                 $modalInstance.close(this.model.messageBoard);
    //             }
    //         }, 100);
    //     });
  };

  public close(status: any) {
    let savParamters: clientViewDlgModelArgs = {
      status: status,
      data: this.inputData,
    };
    this.dialogRef?.close(savParamters);
  }
}

export class ProposalEntity {
  proposalId: any;
  messageId: any;
  printType: any;
  printCorrespondence: any;
  isReprint: any;
  isSummary: any;
  isManual: any;
  isPasswordProtected: any;
  fileModels: SimpleFileEntity[] = [];
}

export class SimpleFileEntity {
  fileId!: string;
  description!: string;
}
