import * as Enums from "./../../../framework/enum.shared";
import * as InterfaceProduct from "./../../../framework/interface.product";

import { BaseMapper } from "./../../product/baseMapper";
import { EndorsementHistory } from "./../base/endorsement-history.model";
import { ExceptionManager } from "./../../../framework/utils/exception-manager";
import { GUID } from "./../../../framework/domain-entity/guid";
import { Global } from "../../../shared/global";
import { ProposalEntity } from "./../base/proposal-entity.model";
import { QuestionEntity } from "./../base/question-entity.model";
import { QuestionEntityMapper } from "./question-entity.mapper";
import { common } from "./../../../framework/utils/common";

/** Mapper to map proposal model between front end capture model and server side entity */
export class ProposalEntityMapper extends BaseMapper<
  ProposalEntity,
  InterfaceProduct.IProposalApiModel
> {
  questionEntityMapper: QuestionEntityMapper = new QuestionEntityMapper();

  /** To map front end model to server side entity */
  map(proposalEntity: ProposalEntity): InterfaceProduct.IProposalApiModel {
    // utilized the exact same frontEndModel object by default
    // recommended to do the mapping properly to avoid unnecessary data passed to server

    let questionToPassToServer: QuestionEntity[] = [];
    if (common.isDefined(proposalEntity.questions)) {
      // ignored label question, cause server does not require it
      questionToPassToServer = proposalEntity.questions!.filter(
        (q) => q.controlType !== Enums.UiControlType.LabelQuestion
      );
    }

    // TODO: to complete all fields mapping
    const apiModel: InterfaceProduct.IProposalApiModel = {
      productId: proposalEntity.productId!.toString(),
      proposalId: proposalEntity.proposalId!.toString(),
      customerId: common.isDefined(proposalEntity.customerMappingID)
        ? proposalEntity.customerMappingID
        : 0,
      customerAddressId: common.isDefined(
        proposalEntity.customerMappingAddressID
      )
        ? proposalEntity.customerMappingAddressID
        : 0,
      questions: this.questionEntityMapper.mapList(questionToPassToServer),
    };

    if (common.isDefined(proposalEntity.children)) {
      apiModel.children = new Array<InterfaceProduct.IProposalApiModel>();
      proposalEntity.children?.forEach((child) => {
        apiModel.children?.push(this.map(child));
      });
    }

    if (common.isDefined(proposalEntity.endorsementClauseTable)) {
      /** Map endorsement clause table */
      apiModel.endorsementClauseTable = proposalEntity.endorsementClauseTable;
    }

    if (common.isDefined(proposalEntity.endorsementHistory)) {
      /** Map endorsement history table */
      apiModel.endorsementHistoryModel = proposalEntity.endorsementHistory;
    }

    apiModel.aux = {};

    if (common.isDefined(proposalEntity.producerUserId)) {
      apiModel.aux.producerUserId = proposalEntity.producerUserId;
    }

    if (common.isDefined(proposalEntity.producerOrganisationId)) {
      apiModel.aux.producerOrganisationId =
        proposalEntity.producerOrganisationId;
    }

    if (common.isDefined(proposalEntity.brokerCommission)) {
      apiModel.aux.bc = proposalEntity.brokerCommission;
    }

    if (common.isDefined(proposalEntity.endorsementType)) {
      apiModel.endorsementType = proposalEntity.endorsementType;
    }

    if (common.isDefined(proposalEntity.loanRedeemed)) {
      apiModel.loanRedeemed = proposalEntity.loanRedeemed;
    }

    if (common.isDefined(proposalEntity.auxiliary)) {
      apiModel.aux = proposalEntity.auxiliary;

      if (common.isDefined(proposalEntity.auxiliary.actionReason)) {
        apiModel.aux.actionReason = proposalEntity.auxiliary.actionReason;
      }

      if (
        common.isDefined(proposalEntity.auxiliary.isPremiumManualOverridden)
      ) {
        apiModel.aux.isPremiumManualOverridden =
          proposalEntity.auxiliary.isPremiumManualOverridden;
      }

      if (
        common.isDefined(
          proposalEntity.auxiliary.IsLoanRedeemedValidationMessage
        )
      ) {
        apiModel.aux.IsLoanRedeemedValidationMessage =
          proposalEntity.auxiliary.IsLoanRedeemedValidationMessage;
      }

      if (
        common.isDefined(
          proposalEntity.auxiliary.IsRenewalYearPendingCheckerValidationMessage
        )
      ) {
        apiModel.aux.IsRenewalYearPendingCheckerValidationMessage =
          proposalEntity.auxiliary.IsRenewalYearPendingCheckerValidationMessage;
      }

      if (
        common.isDefined(
          proposalEntity.auxiliary.IsPreviousYearPendingCheckerValidationMessage
        )
      ) {
        apiModel.aux.IsPreviousYearPendingCheckerValidationMessage =
          proposalEntity.auxiliary.IsPreviousYearPendingCheckerValidationMessage;
      }
    }

    if (common.isDefined(proposalEntity.originalInceptionDate)) {
      apiModel.originalInceptionDate = proposalEntity.originalInceptionDate;
    }

    return apiModel;
  }

  /** To map server side entity to front end model */
  reverseMap(serverEntity: InterfaceProduct.IProposalApiModel): ProposalEntity {
    // TODO: to complete all fields mapping
    const proposalModel = new ProposalEntity();

    proposalModel.serverEntity = serverEntity;
    proposalModel.proposalId = new GUID(serverEntity.proposalId);
    proposalModel.productId = new GUID(serverEntity.productId);
    proposalModel.proposalStatus = serverEntity.proposalStatus!;
    proposalModel.proposalType = serverEntity.proposalType!;
    proposalModel.questions = this.questionEntityMapper.reverseMapList(
      serverEntity.questions!
    );
    proposalModel.transType = serverEntity.transType;
    proposalModel.proposalReadOnly = Global.toBoolean(serverEntity.readOnly!);
    proposalModel.isMigrated = serverEntity.isMigrated;
    proposalModel.title = serverEntity.title;
    proposalModel.customerMappingID = serverEntity.customerId;
    proposalModel.customerMappingAddressID = serverEntity.customerAddressId;
    proposalModel.downloadableDocuments = serverEntity.documents;

    proposalModel.bindQuestionDictionary();
    proposalModel.bindQuestionPanelDictionary();

    if (Global.isDefined(serverEntity.header)) {
      proposalModel.header = [];
      serverEntity.header?.forEach((h) => {
        proposalModel.header?.push({
          title: h.l,
          value: h.v,
        });
      });
    }

    if (Global.isDefined(serverEntity.quoteNumber)) {
      proposalModel.quoteNumber = serverEntity.quoteNumber;
    }

    if (Global.isDefined(serverEntity.policyNumber)) {
      proposalModel.policyNumber = serverEntity.policyNumber;
    }

    if (Global.isDefined(serverEntity.policyCertificateNumber)) {
      proposalModel.policyCertificateNumber =
        serverEntity.policyCertificateNumber;
    }

    if (Global.isDefined(serverEntity.validationMessage)) {
      proposalModel.validationsMessages = serverEntity.validationMessage;
    }

    if (Global.isDefined(serverEntity.bankReferenceNo)) {
      proposalModel.bankReferenceNo = serverEntity.bankReferenceNo;
    }

    if (Global.isDefined(serverEntity.masterPolicyNumber)) {
      proposalModel.masterPolicyNumber = serverEntity.masterPolicyNumber;
    }

    if (Global.isDefined(serverEntity.clientCode)) {
      proposalModel.clientCode = serverEntity.clientCode;
    }

    if (Global.isDefined(serverEntity.makerUserId)) {
      proposalModel.makerUserId = serverEntity.makerUserId;
    }
    if (Global.isDefined(serverEntity.checkerUserId)) {
      proposalModel.checkerUserId = serverEntity.checkerUserId;
    }

    if (Global.isDefined(serverEntity.makerUserName)) {
      proposalModel.makerUserName = serverEntity.makerUserName;
    }
    if (Global.isDefined(serverEntity.checkerUserName)) {
      proposalModel.checkerUserName = serverEntity.checkerUserName;
    }
    if (Global.isDefined(serverEntity.openItems)) {
      proposalModel.openItems = serverEntity.openItems;
    }

    if (Global.isDefined(serverEntity.endorsementType)) {
      proposalModel.endorsementType = serverEntity.endorsementType;
    }

    if (Global.isDefined(serverEntity.loanRedeemed)) {
      proposalModel.loanRedeemed = serverEntity.loanRedeemed;
    }

    if (Global.isDefined(serverEntity.pendingTransaction)) {
      proposalModel.pendingTransaction = serverEntity.pendingTransaction;
    }

    if (Global.isDefined(serverEntity.originalInceptionDate)) {
      proposalModel.originalInceptionDate = serverEntity.originalInceptionDate;
    }

    if (Global.isDefined(serverEntity.minimumEffectiveDate)) {
      proposalModel.minimumEffectiveDate = serverEntity.minimumEffectiveDate;
    }

    /** map auxiliary data */
    if (Global.isDefined(serverEntity.aux)) {
      proposalModel.auxiliary = serverEntity.aux;

      // approval history
      if (Global.isDefined(serverEntity.aux.ApprovalHistory)) {
        proposalModel.approvalHistory = [];
        //https://stackoverflow.com/questions/57086672/element-implicitly-has-an-any-type-because-expression-of-type-string-cant-b
        const approvalHistoryDictionary: Record<string, any> = {};

        serverEntity.aux.ApprovalHistory.forEach(
          (history: {
            ApprovedRules: { QuestionName: string | number; Value: any }[];
            ProposalId: string | number;
          }) => {
            const rules: InterfaceProduct.IApprovedRules[] = [];
            const rulesDictionary: Record<string, any> = {};

            if (Global.isDefined(history.ApprovedRules)) {
              history.ApprovedRules.forEach(
                (rule: { QuestionName: string | number; Value: any }) => {
                  rules.push({
                    questionName: String(rule.QuestionName),
                    value: rule.Value,
                  });

                  rulesDictionary[rule.QuestionName] = rule.Value;
                }
              );
            }

            proposalModel.approvalHistory?.push({
              proposalId: String(history.ProposalId),
              approvedRules: rules,
            });

            approvalHistoryDictionary[history.ProposalId] = rulesDictionary;
          }
        );

        proposalModel.approvalHistoryDictionary = approvalHistoryDictionary;
      }
      proposalModel.approvalHistory = serverEntity.aux.ApprovalHistory;

      if (Global.isDefined(serverEntity.aux.bc)) {
        proposalModel.brokerCommission = serverEntity.aux.bc;
      }

      if (Global.isDefined(serverEntity.aux.poid)) {
        proposalModel.producerOrganisationId = serverEntity.aux.poid;
      }

      if (Global.isDefined(serverEntity.aux.puid)) {
        proposalModel.producerUserId = serverEntity.aux.puid;
      }

      if (Global.isDefined(serverEntity.aux.puid)) {
        proposalModel.producerUserId = serverEntity.aux.puid;
      }

      if (Global.isDefined(serverEntity.aux.preqs)) {
        proposalModel.bindPreviousPolicyQuestionDictionary(
          serverEntity.aux.preqs
        );
      }

      if (Global.isDefined(serverEntity.aux.actionReason)) {
        proposalModel.auxiliary.actionReason = serverEntity.aux.actionReason;
      }

      /** flag to determine whether system is allowed to calculate premium */
      if (Global.isDefined(serverEntity.aux.isPremiumManualOverridden)) {
        proposalModel.auxiliary.isPremiumManualOverridden =
          serverEntity.aux.isPremiumManualOverridden;
      }

      /** map quotes */
      if (Global.isDefined(serverEntity.aux.qt)) {
        proposalModel.quotes = serverEntity.aux.qt;
      }
    }

    if (common.isDefined(serverEntity.childSchema)) {
      if (common.isUndefinedOrNull(serverEntity.childSchema?.questions)) {
        ExceptionManager.argumentNullGuard(
          serverEntity.childSchema?.questions,
          "serverEntity.childSchema.questions"
        );
      }
      proposalModel.childQuestionSchema =
        this.questionEntityMapper.reverseMapList(
          serverEntity.childSchema!.questions!
        );
      proposalModel.children = new Array<ProposalEntity>();
      if (common.isDefined(serverEntity.children)) {
        serverEntity.children?.forEach((child) => {
          proposalModel.children?.push(this.reverseMap(child));
        });
      }
    }

    if (Global.isDefined(serverEntity.endorsementClauseTable)) {
      /** Map endorsement clause table */
      proposalModel.endorsementClauseTable =
        serverEntity.endorsementClauseTable;
    }

    if (Global.isDefined(serverEntity.endorsementHistoryModel)) {
      /** Map endorsement history table */
      proposalModel.endorsementHistory = new Array<EndorsementHistory>();
      if (common.isDefined(serverEntity.endorsementHistoryModel)) {
        serverEntity.endorsementHistoryModel?.forEach((endorsement) => {
          proposalModel.endorsementHistory?.push({
            transType: endorsement.tt,
            endorsementType: endorsement.et,
            proposalStartDate: endorsement.prosd,
            proposalEndDate: endorsement.proed,
            policyEffectiveDate: endorsement.ped,
            policyExpiryDate: endorsement.pexd,
            dateCreated: endorsement.dc,
            calculationMethod: endorsement.cm,
          });
        });
      }
    }


    if (Global.isDefined(serverEntity.isPolicyMasked)) {
      proposalModel.isPolicyMasked = serverEntity.isPolicyMasked;
    }

    if (Global.isDefined(serverEntity.MaskedDate)) {
      proposalModel.MaskedDate = serverEntity.MaskedDate;
    }

    return proposalModel;
  }
}
