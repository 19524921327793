import { Component, Input, OnInit } from "@angular/core";
import { KeyValue } from "src/app/framework/domain-entity/key-value";
import { common } from "src/app/framework/utils/common";
import { Proposal } from "src/app/models/new-product/base/proposal.model";
import { MortgageFireSingaporeProposal } from "src/app/models/new-product/sme/mortgage-fire/singapore/base/mf-sg-proposal";
import { IdentityRolesService } from "src/app/services/identity-roles.service";
import { MfSgProposalService } from "src/app/services/new-product/sme/mortgage-fire/singapore/mf-sg-proposal.service";
import * as Enum from "src/app/framework/enum.shared";
import * as EnumSG from "src/app/models/new-product/sme/mortgage-fire/singapore/base/mf-sg-enums";
import * as FinanceConstant from "src/app/finance/finance-constant";
import { MfSgBaseComponent } from "../mf-sg-base.component";
import { proposalCommon } from "src/app/components/new-product/base/utils/proposal-common";
import { DocumentService } from "src/app/services/document/document.service";
import { CdkDialogService } from "src/app/services/dialog/cdk-dialog.service";
import { OpenItemDetailsComponent } from "src/app/components/accounting/open-item/view-details/open-item-details.component";
import {SharedService} from "src/app/shared/shared.service"

@Component({
  selector: "mf-sg-header",
  templateUrl: "./mf-sg-header.component.html",
  styleUrls: ["./mf-sg-header.component.scss"],
})
export class MfSgHeaderComponentComponent
  extends MfSgBaseComponent
  implements OnInit
{
  @Input() proposal!: MortgageFireSingaporeProposal; //temp using @Input()
  transTypeText!: string;
  proposalStatusText!: string;
  pid!: string;
  isDisplay: boolean = false;
  docTypeText!: string;
  displayChecker: boolean = false;
  displayMaker: boolean = false;
  displayOpenItem: boolean = false;
  displayOriginalPolicyNo: boolean = false;
  transOpenItemStatus!: string;
  //dbsep-8115
  isPolicyMasked!: string;
  maskedDate!: Date;
  displayPolicyMasked: boolean = false;

  public openItemId!: string;
  public openItemDetailsOpened: boolean = false;

  constructor(
    private proposalService: MfSgProposalService,
    public identityRolesService: IdentityRolesService,
    private documentService: DocumentService,
    private cdkDialogService: CdkDialogService,
    private shared:SharedService
  ) {
    super();
  }

  hasQuoteNumber() {
    if (common.isDefined(this.proposal)) {
      return (
        this.proposal!.quoteNumber !== "" &&
        this.proposal!.quoteNumber !== undefined
      );
    }
    return false;
  }

  ngOnDestroy() {
    super.unsubscribeRxjs();
  }

  ngOnInit(): void {
    this.initialize();
    super.subscribeProposalLoadedNotifier(this.proposalLoaded);
    super.subscribeProposalSavedNotifier(this.proposalLoaded);
  }

  initialize() {}

  private proposalLoaded = (event: KeyValue<Proposal>) => {
    this.proposal = event.value as MortgageFireSingaporeProposal;
    if (
      this.proposal.transType === Enum.TransType.END ||
      this.proposal.transType === Enum.TransType.CAN
    ) {
      this.isDisplay = true;
    }
    if (!common.isUndefinedOrNull(this.proposal.openItems)) {
      if (this.proposal.openItems.length > 0) {
        this.displayOpenItem = true;
        this.transOpenItemStatus =
          FinanceConstant.openItemDisplayStatus[
            this.proposal.openItems[0].Status
          ];
      }
    } else {
      this.displayOpenItem = false;
    }

    this.proposalStatusText = proposalCommon.translateProposalStatus(
      this.proposal.proposalStatus,
      this.proposal.transType!
    );
    this.transTypeText = proposalCommon.translateTransType(
      this.proposal!.transType!,
      this.proposal.endorsementType!
    );
    this.pid = this.proposal.proposalId!.toId.toString();
    this.displayChecker =
      common.isDefined(this.proposal.checkerUserName) &&
      this.proposal.productType !== EnumSG.MortgageFireSgProduct.CTHDB &&
      this.proposal.productType !== EnumSG.MortgageFireSgProduct.CTMFPTE &&
      this.proposal.productType !== EnumSG.MortgageFireSgProduct.CTMFCONDO
        ? true
        : false;
    this.displayMaker =
      common.isDefined(this.proposal.makerUserName) &&
      this.proposal.productType !== EnumSG.MortgageFireSgProduct.CTHDB &&
      this.proposal.productType !== EnumSG.MortgageFireSgProduct.CTMFPTE &&
      this.proposal.productType !== EnumSG.MortgageFireSgProduct.CTMFCONDO
        ? true
        : false;
    if (this.proposal.documents.length > 0) {
      const newDocuments: Array<KeyValue<string>> = [];
      this.proposal.documents.forEach((doc: Enum.DocumentType) => {
        const docText: string = proposalCommon.translateDocumentType(doc);
        newDocuments.push(new KeyValue(String(doc), docText));
      });
      this.proposal.documents = newDocuments;
    }

    /** The original Policy Number will be available only for migrated policies */
    const replacingPolicyNo =
      this.proposal.qsMaster.qs_master_replacePolicyNo!.readAnswerAsString();
    this.displayOriginalPolicyNo =
      common.isDefined(replacingPolicyNo) &&
      replacingPolicyNo !== "" &&
      replacingPolicyNo !== null
        ? true
        : false;


    //dbsep-8115
    this.isPolicyMasked = this.proposal.isPolicyMasked;
    this.shared.setPolicyMasked(this.isPolicyMasked);
    //dbsep-8115
    this.maskedDate = this.proposal.MaskedDate;
    this.shared.setMaskedDate(this.maskedDate);
    //dbsep-8115
    this.displayPolicyMasked = this.isPolicyMasked == "1"?true:false;
  };

  public downloadDocument = (docType: any,pid? : any): void => {
    const params = {
      proposalId: this.proposal.proposalId!.toString(),
      documentType: docType,
    };
    this.documentService.downloadProposalDocument(params);
  };

  public showDetails = (): void => {
    const postData = { openItemId: this.proposal.openItems[0].Id };

    // fix cdk init issue
    // const openItemDetailsDialogRef = this.cdkDialogService.open(
    //   OpenItemDetailsComponent,
    //   {
    //     data: postData,
    //   }
    // );

    // openItemDetailsDialogRef.afterClosed().subscribe((result: any) => {
    //   // Subscription runs after the dialog closes
    //   if (result) {
    //     this.confirmationModalCloseForActions(result);
    //   }
    // });
    this.openItemId = postData.openItemId;
    this.openItemDetailsOpened = true;
  };
  confirmationModalCloseForActions = (data: any): void => {
    this.openItemDetailsOpened = false;
  };
}
