import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  message!: string;
  mDate!: Date;
  constructor() { }

  setPolicyMasked(data: string){
    this.message = data
  }

  getPolicyMasked(){
    return this.message
  }

  setMaskedDate(data: Date){
     this.mDate = data
  }

  getMaskedDate(){
    return this.mDate
  }
}
